import { Seo, Text, TextInput } from 'components';
import Header from 'components/Header';
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { usePageView, useQuizData } from 'utils/hooks';
import Loader from '../calculating/components/Loader';
import Button from '../results/components/StartNowButton';
import { mobile, useQuery } from 'styles/breakpoints';
import { AppState } from 'state/types';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'apis/history';
import { config } from 'config';
import axios from 'axios';
import { handleError } from 'utils/error';
import { getCountries } from 'countries-and-states';
import Select, { StylesConfig } from 'react-select';
import iso3166 from 'iso-3166-2';
import { CircularProgress } from '@material-ui/core';
import { klaviyoShipLink } from 'utils/klavyio';
import theme from 'utils/theme';
import { updateLead, updateShippingAddress } from 'state/user/effects';
import { Select as StyledSelect } from './components/Select';
import { AddressAutocomplete } from './components/AddressAutocomplete';
import { getAddressDetails } from 'utils/google-places';
import InputX from '../../../assets/icons/input-x.svg';
import InputCheck from '../../../assets/icons/inputCheck.svg';
import InputError from '../../../assets/icons/inputError.svg';
import SuccessAndError from './components/SuccessAndError';
import { phoneValidation } from 'utils/validations';
import { normalizeStates } from 'utils/localization';

interface FormData {
  country: string;
  state: string | { label: string; value: string };
  nameSurname: string;
  city: string;
  street: string;
  apartament: string;
  phone: string;
  postalCode: string;
}

const defaultValues: FormData = {
  country: '',
  state: '',
  nameSurname: '',
  city: '',
  street: '',
  apartament: '',
  phone: '',
  postalCode: '',
};

const Shipping = () => {
  const { selected_plans, user, code, shipping_address, upsell_products } =
    useSelector((state: AppState) => state.user);

  const { isTablet } = useQuery();

  const [formValues, setFormValues] = useState<FormData>(defaultValues);
  const [countries, setCountries] = useState<any[]>([]);
  const [shippingErrors, setShippingErrors] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const [inputErrors, setInputErrors] = useState({
    country: {
      show: false,
      message: 'Choose your country',
    },
    nameSurname: {
      show: false,
      message: 'Enter your full name',
    },
    street: {
      show: false,
      message: 'Enter your address',
    },
    streetLength: {
      show: false,
      message:
        'First and second lines of the address are limited to 30 characters each, this includes spaces and punctuation.',
    },
    city: {
      show: false,
      message: 'Enter your city',
    },
    state: {
      show: false,
      message: 'Choose your state',
    },
    postalCode: {
      show: false,
      message: 'Enter your Zip code',
    },
    phone: {
      show: false,
      message: 'Enter your phone number',
    },
    invalidPhone: {
      show: false,
      message: 'Please enter a valid phone number',
    },
  });
  const [states, setStates] = useState<any>([]);
  const [isShippingAdressComplete, setIsShippingAdressComplete] =
    useState<boolean>(false);

  const countryInputRef = useRef(null);
  const data = useQuizData('shipping');
  const checkoutData = useQuizData('checkout');
  const pageOrder = checkoutData?.pageOrder || [];
  const { quiz_answers, geolocation } = useSelector(
    (state: AppState) => state.user,
  );
  const dogBreedList = useSelector((state: AppState) => state.dogBreed.list);

  const { goToRegister, goToSuccess, goToUpgrade } = useRouter();
  const upgradeSequences = useQuizData('upgradeSequences');
  const caseParam = new URLSearchParams(location.search).get('case');
  const isFromSupportCase = caseParam === 'support';

  const dispatch = useDispatch();
  usePageView({
    country: geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      geolocation?.iso_country || '',
      geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user?.email.trim() || 'no-email-fallback',
    client_code: code,
  });

  useEffect(() => {
    fetchCountries();
    if (shipping_address) {
      const formValueObj = {
        country: shipping_address.country_code,
        state: shipping_address.state_code,
        nameSurname: shipping_address.name,
        city: shipping_address.city,
        street: shipping_address.street1,
        apartament: shipping_address.street2,
        phone: shipping_address.phoneNumber,
        postalCode: shipping_address.postcode,
      };
      Object.entries(formValueObj).forEach(([key, value]) => {
        onInputChange(key, value);
      });
    }
  }, []);

  const fetchCountries = async () => {
    try {
      const countriesData = await getCountries('en');
      const mapedCountries = countriesData.map(item => ({
        value: item.countryCode,
        label: item.countryName,
      }));
      setCountries(mapedCountries);
    } catch (error) {
      console.error('Error fetching countries:', error);
    }
  };

  const validateBeforeSubmit = async (
    event: React.FormEvent<HTMLFormElement>,
  ) => {
    event.preventDefault();
    let hasErrors = false;
    let newErrors = { ...inputErrors };

    // Validate each field
    if (!formValues.country) {
      newErrors.country.show = true;
      hasErrors = true;
    }
    if (!formValues.nameSurname) {
      newErrors.nameSurname.show = true;
      hasErrors = true;
    }
    if (!formValues.street) {
      newErrors.street.show = true;
      hasErrors = true;
    }
    if (formValues.street.length >= 30 || formValues.apartament.length >= 30) {
      newErrors.streetLength.show = true;
      hasErrors = true;
    }
    if (!formValues.city) {
      newErrors.city.show = true;
      hasErrors = true;
    }
    if (!formValues.state) {
      newErrors.state.show = true;
      hasErrors = true;
    }
    if (!formValues.postalCode) {
      newErrors.postalCode.show = true;
      hasErrors = true;
    }
    const isValidPhone = phoneValidation(formValues.phone);
    if (!isValidPhone) {
      if (!formValues.phone) {
        newErrors.phone.show = true;
        newErrors.invalidPhone.show = false;
      } else {
        newErrors.phone.show = false;
        newErrors.invalidPhone.show = true;
      }
      hasErrors = true;
    }
    // ... repeat for other fields

    if (hasErrors) {
      setInputErrors(newErrors);
    } else {
      // Dispatch an action to submit your form data
      await handleSubmit(event);
    }
  };

  const buildValueKey = (item: string) =>
    item.toLowerCase().replace(/\s+/g, '_');

  const colorWord = (item: string) => {
    let questionMarkIndex = item.indexOf('?');
    let lastUnderscoreIndex = item.lastIndexOf('_', questionMarkIndex);
    let color = item.substring(lastUnderscoreIndex + 1, questionMarkIndex);
    return color;
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setLoading(true);
    setIsShippingAdressComplete(true);
    dispatch(
      updateShippingAddress({
        userEmail: user?.email || ''.trim(),
        city: formValues.city.trim(),
        country_code: formValues.country.trim(),
        name: formValues.nameSurname.trim(),
        phoneNumber: formValues.phone.trim(),
        postcode: formValues.postalCode.trim(),
        state_code: {
          value: formValues.state.value,
          label: formValues.state.label,
        },
        street1: formValues.street.trim(),
        street2: formValues.apartament.trim(),
      }),
    );
    try {
      await axios.post(config.DUPLICATE_CHECK, { clientCode: code });
      const bookString = quiz_answers.coverUrl;
      const index = bookString.indexOf('#');
      const finalString = bookString.substring(index);
      const result = finalString.replace(/\.png$/, '');
      axios.post(config.FIREBASE_SHIP_BOOK, {
        hardCover: quiz_answers.hardCover || false,
        bookColor: colorWord(result),
        userName:
          `${quiz_answers.userName.trim()} & ${quiz_answers.userDogName.trim()}`.trim(),
        contentReady:
          dogBreedList.find(
            item =>
              item.value === buildValueKey(quiz_answers.dogs_breed).trim(),
          )?.contentReady || false,
        breed: buildValueKey(quiz_answers.dogs_breed).trim(),
        hairType:
          quiz_answers.dog_coat_type === 'none'
            ? null
            : quiz_answers.dog_coat_type,
        clientCode: code,
        email: user?.email || ''.trim(),
        shippingAddress: {
          userEmail: user?.email || ''.trim(),
          city: formValues.city.trim(),
          country_code: formValues.country.trim(),
          name: formValues.nameSurname.trim(),
          phoneNumber: formValues.phone.trim(),
          postcode: formValues.postalCode.trim(),
          state_code: formValues.state.value.trim(),
          street1: formValues.street.trim(),
          street2: formValues.apartament.trim(),
        },
      });
      // klaviyoShipLink(user?.email || '', quiz_answers.contentReady || false);
      dispatch(
        updateLead(code, {
          quiz_answers: {
            ...quiz_answers,
            shipping_address: {
              userEmail: user?.email || ''.trim(),
              city: formValues.city.trim(),
              country_code: formValues.country.trim(),
              name: formValues.nameSurname.trim(),
              phoneNumber: formValues.phone.trim(),
              postcode: formValues.postalCode.trim(),
              state_code: formValues.state.value.trim(),
              street1: formValues.street.trim(),
              street2: formValues.apartament.trim(),
            },
          },
        }),
      );
    } catch (e) {
      setLoading(false);
      setShippingErrors(prev => [...prev, 'Something has gone wrong!']);
      dispatch(
        updateLead(code, {
          quiz_answers: {
            ...quiz_answers,
            shipping_address: {
              userEmail: user?.email || ''.trim(),
              city: formValues.city.trim(),
              country_code: formValues.country.trim(),
              name: formValues.nameSurname.trim(),
              phoneNumber: formValues.phone.trim(),
              postcode: formValues.postalCode.trim(),
              state_code: formValues.state.value.trim(),
              street1: formValues.street.trim(),
              street2: formValues.apartament.trim(),
            },
          },
        }),
      );
      try {
        await axios.post(config.FIREBASE_SHIPPING_ADDRESS, {
          email: user?.email,
          shippingAddress: {
            userEmail: user?.email || ''.trim(),
            city: formValues.city.trim(),
            country_code: formValues.country.trim(),
            name: formValues.nameSurname.trim(),
            phoneNumber: formValues.phone.trim(),
            postcode: formValues.postalCode.trim(),
            state_code: formValues.state.value.trim(),
            street1: formValues.street.trim(),
            street2: formValues.apartament.trim(),
          },
        });
      } catch (e) {
        handleError(e);
      }
    }
    if (isFromSupportCase) {
      setLoading(false);
      setIsShippingAdressComplete(true);
      return;
    }
    let matchFound = false;

    pageOrder.forEach(obj => {
      Object.entries(obj).forEach(([key, arr]) => {
        if (matchFound) return;
        arr.some(item => {
          const match =
            selected_plans?.find(element => element.key === item) ||
            upsell_products?.find(element => element.key === item);
          if (match) {
            switch (key) {
              case 'register': {
                matchFound = true;
                goToRegister();
                break;
              }
            }
            return true; // Exit the loop when a match is found
          }
        });
      });
    });

    if (!matchFound) {
      goToSuccess();
    }
  };

  const onAddressSelect = async (address: string) => {
    const details = await getAddressDetails(address);
    const countryObj = countries.find(
      country => country.value === details?.country,
    );
    handleCountryCange(countryObj);
    const stateObj = renderStateOptions(details?.country).find(
      state => state.label === details?.state,
    );

    const stateState = {
      value: iso3166.subdivision(stateObj?.value)?.regionCode || '',
      label: iso3166.subdivision(stateObj?.value)?.name || '',
    };

    if (details) {
      setFormValues(prev => ({
        ...prev,
        state: stateState ?? '',
        street: details?.address ?? '',
        city: details?.city ?? '',
        postalCode: details?.postalCode ?? '',
        country: countryObj.value ?? '',
      }));
    }
  };

  const handleCountryCange = (item: any) => {
    setStates(renderStateOptions(item.value));
  };

  const renderStateOptions = (item: any) => {
    if (item.length !== 0) {
      const transformed = Object.entries(iso3166.data[item].sub).map(
        ([value, { name: label }]) => ({
          value,
          label,
        }),
      );
      return transformed;
    } else {
      return [];
    }
  };

  const onInputChange = (
    inputKey: keyof FormData,
    value: string | { label: string; value: string },
  ) => {
    setFormValues(prev => {
      let obj = { ...prev };
      obj[inputKey] = value;

      if (inputKey === 'country' && value === '') {
        obj['state'] = '';
      }

      return obj;
    });

    if (inputKey === 'country') {
      setStates(renderStateOptions(value));
    }

    if (
      typeof value === 'string'
        ? value.trim() !== ''
        : value.value.trim() !== ''
    ) {
      setInputErrors(prevErrors => ({
        ...prevErrors,
        [inputKey]: { ...prevErrors[inputKey], show: false },
      }));
    }
  };

  const renderBottomComponent = () => {
    if (loading)
      return (
        <LoaderContainer>
          <CircularProgress />
        </LoaderContainer>
      );
    // if (isRegisterComplete) return <RegisterCompleted />;
    if (isShippingAdressComplete) {
      return <SuccessAndError shippingErrors={shippingErrors} />;
    }
    return <StyledButton type="submit">Submit</StyledButton>;
  };

  if (!data) {
    return <Loader />;
  }

  const handlePhoneChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputVal = e.target.value;
    inputVal = inputVal.replace(/[^0-9+]/g, '');

    setFormValues(prevFormValues => ({
      ...prevFormValues,
      phone: inputVal,
    }));
  };

  const countryStyled: StylesConfig<any> = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '100px',
      height: '3rem',
      background: 'white',
      fontFamily: 'Avenir',
      fontSize: '1rem',
      fontWeight: '400',
      border: '1px solid rgb(228, 228, 235)',
      boxShadow: 'unset',
      ':active': {
        borderColor: 'rgb(228, 228, 235)',
      },
      ':hover': {
        borderColor: 'rgb(228, 228, 235)',
      },
    }),
    valueContainer: styles => ({
      ...styles,
      marginLeft: '1.5rem',
      padding: 'unset',
    }),
    menu: styles => ({ ...styles, margin: 'unset' }),
    input: styles => ({ ...styles }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  const stateStyled: StylesConfig<any> = {
    control: styles => ({
      ...styles,
      backgroundColor: 'white',
      borderRadius: '8px',
      height: '3rem',
      background: '#F9F9F9',
      fontFamily: 'Inter',
      fontSize: '1rem',
      fontWeight: '400',
      border: '1px solid rgb(228, 228, 235)',
      boxShadow: 'unset',
      ':active': {
        borderColor: 'rgb(228, 228, 235)',
      },
      ':hover': {
        borderColor: 'rgb(228, 228, 235)',
      },
      cursor: 'text',
    }),
    valueContainer: styles => ({
      ...styles,
      marginLeft: '0.5rem',
      padding: 'unset',
    }),
    menu: styles => ({ ...styles, margin: 'unset' }),
    input: styles => ({
      ...styles,
      ':-internal-autofill-selected': {
        appearance: 'menulist-button',
        backgroundImage: 'none !important',
        backgroundColor:
          '-internal-light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important',
        color: 'fieldtext !important',
      },
    }),
    placeholder: styles => ({ ...styles }),
    singleValue: (styles, { data }) => ({ ...styles }),
  };

  return (
    <>
      <Seo title="Dog Training Book | Raising Dog" />
      <Header logoVariant="center" color={'lightBg'} />
      <Container>
        <TitleContainer>
          <Title color="primaryDark" type={isTablet ? 'h3' : 'h1S600'}>
            {data.title}
          </Title>
          <SubTitle
            color="dark80"
            type="body"
            dangerouslySetInnerHTML={{ __html: data.subTitle }}
          />
        </TitleContainer>

        <Form onSubmit={validateBeforeSubmit}>
          <LabelStyled>
            {data.countryLabel} <Required>*</Required>
          </LabelStyled>
          <StyledSelect
            value={formValues.country}
            options={countries}
            placeholder="Select"
            name="country"
            onChange={e => onInputChange('country', e.target.value)}
          />
          {inputErrors.country.show && (
            <InputErrorContainer>
              <StyledInputError />
              <InputErrorText>{inputErrors.country.message}</InputErrorText>
            </InputErrorContainer>
          )}
          {!!formValues.country && (
            <InputErrorContainer>
              <StyledInputCheck />
              <InputCheckText>Free shipping</InputCheckText>
            </InputErrorContainer>
          )}
          <InputContainer errorAbove={inputErrors.country.show}>
            <LabelStyled>
              {data.nameLabel} <Required>*</Required>
            </LabelStyled>
            <InputXContainer>
              {!!formValues.nameSurname.length && (
                <StyledX onClick={() => onInputChange('nameSurname', '')} />
              )}

              <StyledInput
                onChange={e => onInputChange('nameSurname', e.target.value)}
                name="name"
                value={formValues.nameSurname}
              />
            </InputXContainer>
            {inputErrors.nameSurname.show && (
              <InputErrorContainer>
                <StyledInputError />
                <InputErrorText>
                  {inputErrors.nameSurname.message}
                </InputErrorText>
              </InputErrorContainer>
            )}
          </InputContainer>
          <InputContainer errorAbove={inputErrors.nameSurname.show}>
            <LabelStyled>
              {data.streetLabel} <Required>*</Required>
            </LabelStyled>
            <InputXContainer>
              {!!formValues.street.length && (
                <StyledX onClick={() => onInputChange('street', '')} />
              )}
              <AddressAutocomplete
                value={formValues.street}
                onChange={value => onInputChange('street', value)}
                onSelect={value => onAddressSelect(value)}
                styles={stateStyled}
                placeholder="Enter here"
              />
            </InputXContainer>
          </InputContainer>
          <ApartamentContainer>
            <InputXContainer>
              {!!formValues.apartament.length && (
                <StyledX onClick={() => onInputChange('apartament', '')} />
              )}

              <StyledInput
                onChange={value =>
                  onInputChange('apartament', value.target.value)
                }
                placeholder="Apt, Suite, Unit, Building (optional)"
                name="apartament"
                autoComplete="on"
                value={formValues.apartament}
              />
            </InputXContainer>
            {inputErrors.street.show && (
              <InputErrorContainer>
                <StyledInputError />
                <InputErrorText>{inputErrors.street.message}</InputErrorText>
              </InputErrorContainer>
            )}
            {inputErrors.streetLength.show && (
              <InputErrorContainer>
                <StyledInputError />
                <InputErrorText>
                  {inputErrors.streetLength.message}
                </InputErrorText>
              </InputErrorContainer>
            )}
          </ApartamentContainer>

          <InputContainer errorAbove={inputErrors.street.show}>
            <LabelStyled>
              {data.townLabel} <Required>*</Required>
            </LabelStyled>
            <InputXContainer>
              {!!formValues.city.length && (
                <StyledX onClick={() => onInputChange('city', '')} />
              )}

              <StyledInput
                onChange={e => onInputChange('city', e.target.value)}
                name="city"
                autoComplete="on"
                value={formValues.city}
              />
            </InputXContainer>
            {inputErrors.city.show && (
              <InputErrorContainer>
                <StyledInputError />
                <InputErrorText>{inputErrors.city.message}</InputErrorText>
              </InputErrorContainer>
            )}
          </InputContainer>
          <InputContainer errorAbove={inputErrors.city.show}>
            <LabelStyled>
              {data.stateLabel} <Required>*</Required>
            </LabelStyled>

            <StyledSelect
              value={
                states.find(state => state.label === formValues.state.label)
                  ?.value || ''
              }
              options={states}
              name="state"
              placeholder="Select"
              onChange={e =>
                onInputChange('state', {
                  value: iso3166.subdivision(e.target.value)?.regionCode,
                  label: iso3166.subdivision(e.target.value)?.name,
                })
              }
            />
            {inputErrors.state.show && (
              <InputErrorContainer>
                <StyledInputError />
                <InputErrorText>{inputErrors.state.message}</InputErrorText>
              </InputErrorContainer>
            )}
          </InputContainer>
          <InputContainer errorAbove={inputErrors.state.show}>
            <LabelStyled>
              {data.postalLabel} <Required>*</Required>
            </LabelStyled>
            <InputXContainer>
              {!!formValues.postalCode.length && (
                <StyledX onClick={() => onInputChange('postalCode', '')} />
              )}
              <StyledInput
                onChange={e => onInputChange('postalCode', e.target.value)}
                name="postalCode"
                autoComplete="on"
                value={formValues.postalCode}
              />
            </InputXContainer>
            {inputErrors.postalCode.show && (
              <InputErrorContainer>
                <StyledInputError />
                <InputErrorText>
                  {inputErrors.postalCode.message}
                </InputErrorText>
              </InputErrorContainer>
            )}
          </InputContainer>
          <InputContainer errorAbove={inputErrors.postalCode.show}>
            <LabelStyled>
              {data.phoneLabel} <Required>*</Required>
            </LabelStyled>
            <InputXContainer>
              {!!formValues.phone.length && (
                <StyledX onClick={() => onInputChange('phone', '')} />
              )}
              <StyledInput
                onChange={handlePhoneChange}
                name="phone"
                autoComplete="on"
                type="text"
                value={formValues.phone}
              />
            </InputXContainer>
            {inputErrors.phone.show && (
              <InputErrorContainer>
                <StyledInputError />
                <InputErrorText>{inputErrors.phone.message}</InputErrorText>
              </InputErrorContainer>
            )}
            {inputErrors.invalidPhone.show && (
              <InputErrorContainer>
                <StyledInputError />
                <InputErrorText>
                  {inputErrors.invalidPhone.message}
                </InputErrorText>
              </InputErrorContainer>
            )}
          </InputContainer>
          {renderBottomComponent()}
        </Form>
      </Container>
    </>
  );
};

export default Shipping;

const InputXContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledX = styled(InputX)`
  position: absolute;
  margin-right: 0.5rem;
  cursor: pointer;
  z-index: 3;
`;

const StyledInputError = styled(InputError)`
  min-width: 0.75rem;
`;

const StyledInputCheck = styled(InputCheck)`
  min-width: 0.75rem;
`;

const InputErrorContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.25rem;
  margin-top: 0.25rem;
`;

const InputErrorText = styled.p`
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.14331rem;
  color: #ff6d5c;
  @media ${mobile} {
    font-size: 0.8rem;
  }
`;

const InputCheckText = styled.p`
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.14331rem;
  color: #2fbe8f;
`;

const ApartamentContainer = styled.div`
  margin-top: 0.5rem;
`;

const ErrorText = styled.p`
  margin-top: 1rem;
  color: #ff0000;
  padding: 0 0.5rem;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const LabelStyled = styled.label`
  padding-bottom: 0.5rem;
  display: block;
  font-family: 'Open Sans';
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 700;
  line-height: 130%; /* 1.1375rem */
  letter-spacing: -0.014rem;
  color: ${({ theme }) => theme.colors.dark90};
`;

const LoaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
`;

const Required = styled.label`
  font-family: Inter;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.dark90};
`;

const Label = styled.label`
  padding-bottom: 0.5rem;
  display: block;
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  margin-left: 1.5rem;
  color: ${({ theme }) => theme.colors.dark100};
`;

const StyledButton = styled(Button)`
  max-width: 560px;
  margin-top: 1.5rem;
  margin-bottom: 4rem;
  width: 100%;
  background: #804da1;
  border-radius: 100px;
`;

const Title = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 28px;
  line-height: 130%;
  letter-spacing: -0.036em;
  color: #804da1;
  max-width: 565px;
  width: 100%;
  text-align: left;
  margin-bottom: 0.5rem;
  @media ${mobile} {
    padding: 0 16px;
  }
`;

const TestTitle = styled.p`
  font-style: normal;
  font-weight: 700;
  font-family: Open Sans;
  font-size: 1.75rem;
  line-height: 130%;
  letter-spacing: -0.036em;
  color: #804da1;
  max-width: 565px;
  width: 100%;
  text-align: left;
  padding: 0.75rem 0;
  @media ${mobile} {
    font-size: 1.125rem;
    padding: 0.75rem 1rem;
  }
`;

const SubTitle = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #28293d;
  max-width: 565px;
  text-align: left;
  width: 100%;
  margin-bottom: 1.5rem;
  @media ${mobile} {
    margin-bottom: 1rem;

    padding: 0 16px;
  }
`;

// const BackArrow = styled(ArrowUpIcon)`
//   rotate: 270deg;
//   height: 1rem;
//   width: 1rem;
// `;

const BackText = styled.p`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  color: rgb(128, 77, 161);
`;

const ButtonContainer = styled.div`
  display: flex;
  max-width: 560px;
  width: 100%;
  align-items: center;
  margin: 0 auto;
  cursor: pointer;
  gap: 0.75rem;
  padding: 0.75rem 0;
  @media ${mobile} {
    padding-left: 1rem;
    justify-content: unset;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Form = styled.form`
  max-width: 560px;
  width: 100%;
  @media ${mobile} {
    padding: 0 16px;
  }
`;

const StyledInput = styled(TextInput)`
  border-radius: 0.5rem;
  border: 1px solid rgba(227, 227, 227, 0.8);
  background: #f9f9f9;
  padding: 0 0.5rem;
`;

const InputContainer = styled.div<{ errorAbove: boolean }>`
  margin-top: ${({ errorAbove }) => (errorAbove ? '0.5rem' : '1.5rem')};
`;

// const HiddenInput = styled.input`
//   position: absolute;
//   left: -9999px;
//   width: 1px;
//   height: 1px;
//   overflow: hidden;
// `;

// const PaymentStepsContainer = styled.div`
//   display: flex;
//   justify-content: center;
//   padding: 1.5rem;

//   @media ${mobile} {
//     padding: 1.5rem 2.125rem;
//   }
//   @media ${smMobile} {
//     padding: 1.5rem 0.75rem;
//   }
// `;

// const PaymentStepsStyled = styled(PaymentSteps)`
//   max-width: 56.25rem;
//   width: 100%;
// `;
